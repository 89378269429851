import React, { useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import './ArticleItem.css'
import BannerList from "../Banners/BannerList";
import PostList from "./PostList";
import { Context } from "../../../index";


const ArticleItem = ({ post, title, id, articleSeoName }) => {
    const { storeblog } = useContext(Context);
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(post, 'text/html');
    const imgTags = parsedHtml.getElementsByTagName('img');

    let photo = '';
    for (let i = 0; i < imgTags.length; i++) {
        if (!imgTags[i].closest('.article_banner_box')) {
            photo = imgTags[i].getAttribute('src');
            break;
        }
    }

    const [isMore, setIsMore] = useState(false);

    useEffect(() => {
        storeblog.setSelectedCategory('');

    }, [])

    return (
        <div className='px-9'>

            <div className='article_box'>
                {post !== ''
                    ? (
                        isMore
                            ? <div dangerouslySetInnerHTML={{ __html: post }} className='article_body' />
                            :
                            <div className='article_body'>
                                <h2>
                                    <img src={photo} alt="photo" />
                                </h2>
                                <h2>{title}</h2>
                                <button className='article_more_btn' onClick={() => setIsMore(!isMore)}>Показать больше</button>
                            </div>
                    )
                    : <div className='article_body'>
                        <div className='loader_box'>
                            <div className="loader"></div>
                        </div>
                    </div>
                }
                <div className='banners_box'>
                    <BannerList id={id} articleSeoName={articleSeoName}/>
                </div>
            </div>
            <div>
                <hr className='my-6 w-[90%] mx-auto' />
                <div className='d-flex flex-wrap justify-content-center mx-2'>

                        <PostList />
                        {/*<BannerList />*/}

                </div>
            </div>
        </div>
    );
};

export default observer(ArticleItem);