import React, {FC, useContext, useState} from 'react';
import {Context} from "../../index";
import MyInput from "../UI/MyInput/MyInput";
import Editoor from "../Posts/Editor/Editoor";
import PostStore from "../../store/postStore";

interface EditBannerModalProps {
    bannerId: string,
    post: string,
    title: string,
    isActive: boolean,
    priority: string,
    description: string,
    link: string,
    handlePopUpOpen: () => void,
}

const EditBannerModal: FC<EditBannerModalProps> = (props) => {
    const {postStore} = useContext(Context)

    const [modalVisible, setModalVisible] = useState(true)
    const [visible, setVisible] = useState(false)
    const [editorHtml, setEditorHtml] = useState(props.post);
    const [title, setTitle] = useState<string>(props.title);
    const [priority, setPriority] = useState<string>(props.priority);
    const [description, setDescription] = useState<string>(props.description)
    const [isActive, setIsActive] = useState<boolean>(props.isActive)
    const [link, setLink] = useState<string>(props.link)

    const setArticleIsActive = () => {
        setIsActive(!isActive)
    }

    const handleChoosePriority = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (/^[0-9]*$/.test(e.target.value)){
            setPriority(e.target.value);
        }
    }

    const Close = () => {
        props.handlePopUpOpen()
    }

    const EditBanner = async () => {
        const thisDate = new Date()

        const id = props.bannerId;
        const date = thisDate.toLocaleDateString()
        const time = thisDate.toLocaleTimeString()

        const post = editorHtml;

        const response = await postStore.editBanner(id, date, time, isActive, title, description, post, link, priority)
        props.handlePopUpOpen()
        postStore.getBanners(1,20)
        console.log(response)
    }

    return (

        <div className="modal show edit_post" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content p-4">
                    <div className='d-flex flex-column align-items-start'>
                        <h3>Редагування банера</h3>
                        <div className='h-9 w-100'>
                            <MyInput
                                type='text'
                                id={'1'}
                                value={title}
                                placeholder={'Заголовок'}
                                icon={'text'}
                                void={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        {/*<div className='h-9 w-100'>*/}
                        {/*    <MyInput*/}
                        {/*        type='text'*/}
                        {/*        id={'2'}*/}
                        {/*        value={description}*/}
                        {/*        placeholder={'Опис статті'}*/}
                        {/*        icon={'text'}*/}
                        {/*        void={(e) => setDescription(e.target.value)}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className='pt-3 w-100'>
                            <MyInput
                                type='text'
                                id={'3'}
                                value={link}
                                placeholder={'Посилланя на офер'}
                                icon={'link-alt'}
                                void={(e) => setLink(e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <MyInput
                                placeholder={'Пріоритетність'}
                                type={'text'}
                                value={priority}
                                void={handleChoosePriority}
                            />
                        </div>
                    </div>
                    <div className='h-9 pt-4'>
                        <div className="form-check">
                            <input className={"form-check-input " + (isActive ? 'bg-black border-black' : '')}
                                   onChange={setArticleIsActive}
                                   checked={isActive}
                                   type="checkbox"
                                   value=""
                                   id="flexCheckDefault2"
                            />
                            <label className="form-check-label" htmlFor="flexCheckDefault2">Показати</label>
                        </div>
                    </div>
                    <div className='h-80 bg-white text-black'>
                        <Editoor
                            editorHtml={editorHtml}
                            setEditorHtml={setEditorHtml}
                            placeholder='Write here...'/>
                    </div>
                    <div className='flex justify-between w-75 pt-4'>
                        <button
                            onClick={Close}
                            className='btn btn-light rounded-md w-25'
                        >
                            Назад
                        </button>
                        <button
                            onClick={EditBanner}
                            className='btn btn-secondary rounded-md mx-2 w-25'
                        >
                            Зберегти зміни
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default EditBannerModal;