import React, {FC, useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import Editoor from "./Editor/Editoor";
import {Context} from "../../index";
import MyInput from "../UI/MyInput/MyInput";
import {useNavigate} from "react-router-dom";
import {RoutesList} from "../../routes";

const CreatePost: FC = () => {
    const {store} = useContext(Context)
    const {postStore} = useContext(Context)
    const navigate = useNavigate();

    const [modalVisible, setModalVisible] = useState(true)
    const [visible, setVisible] = useState(false)
    const [editorHtml, setEditorHtml] = useState('');
    const [priority, setPriority] = useState<string>('');
    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [isTop, setIsTop] = useState<boolean>(false)
    const [isShowCategory, setIsShowCategory] = useState<any>(false)
    const [categoryPost, setCategoryPost] = useState<any>('FINANCE');

    const categoryList = [
        {
            categoryName : 'Фінанси',
            categoryValue : 'FINANCE'
        },
        {
            categoryName : 'здоров\'я',
            categoryValue : 'HEALTH'
        },
        {
            categoryName : 'Політика',
            categoryValue : 'POLITIC'
        },
        {
            categoryName : 'Бізнес',
            categoryValue : 'BUSINESS'
        },
        {
            categoryName : 'Огород',
            categoryValue : 'GARDEN'
        },
        {
            categoryName : 'Пенсії',
            categoryValue : 'PENSIONS'
        },
        {
            categoryName : 'Інше',
            categoryValue : 'ANOTHER'
        },
    ];


    const handleShowCategories = () => {
        setIsShowCategory(!isShowCategory);
    }
    const handleChooseCategories = (value:any) => {
        setCategoryPost(value);
        setIsShowCategory(!isShowCategory);
    }

    const handleChoosePriority = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (/^[0-9]*$/.test(e.target.value)){
            setPriority(e.target.value);
        }
    }

    const setArticleStatus = () => {
        setIsTop(!isTop)
    }

    const CreatePost = async() => {
        const thisDate = new Date()

        const date = thisDate.toLocaleDateString()
        const time = thisDate.toLocaleTimeString()

        const type = isTop;
        const post = editorHtml;
        const category = categoryPost;

        setDescription(title)

        const response = await postStore.addPost(date, time, type, title, description,category, post, priority)
        setTimeout(()=>{
            navigate(RoutesList.POSTS_LIST_ROUTER)
        },1000)
        store.setSideBarActiveID(0)
    }

    return (
        <div  className='p-4 bg-light rounded-md '>
            <div className='flex justify-between pb-4'>
                <div className='w-[500px] flex justify-between'>
                    <h3>Додавання нової статті</h3>
                    <div className='h-9'>
                        <MyInput
                            type='text'
                            id={'1'}
                            value={title}
                            placeholder={'Заголовок'}
                            icon={'text'}
                            void={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    {/*<div className='h-9'>*/}
                    {/*    <MyInput*/}
                    {/*        type='text'*/}
                    {/*        id={'2'}*/}
                    {/*        value={description}*/}
                    {/*        placeholder={'Опис статті'}*/}
                    {/*        icon={'text'}*/}
                    {/*        void={(e) => setDescription(e.target.value)}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className='pt-3'>
                        <p className='text-uppercase mb-1 text-secondary'>Категорія</p>
                        <div className="input-group">
                            <button className="btn btn-outline-secondary dropdown-toggle w-50 text-uppercase"
                                    onClick={handleShowCategories}
                            >{categoryPost}
                            </button>
                            <ul className={"dropdown-menu w-50 " + (isShowCategory ? 'show' : '')}>
                                {
                                    categoryList.map( (e,i) => {
                                        return (
                                            <li className="dropdown-item text-uppercase" key={ i + e.categoryValue } onClick={() => handleChooseCategories(e.categoryValue)}>{e.categoryValue}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='pt-3'>
                        <div className="input-group">
                            <MyInput
                                placeholder={'Пріоритетність'}
                                type={'text'}
                                value={priority}
                                void={handleChoosePriority}
                            />
                        </div>
                    </div>
                </div>
                <div className='h-9 pt-4'>
                    <div className="form-check">
                        <input className={"form-check-input " + (isTop ? 'bg-black border-black' : '')}
                               onChange={setArticleStatus} type="checkbox" value="" id="flexCheckDefault"/>
                        <label className="form-check-label" htmlFor="flexCheckDefault">Додати в топ</label>
                    </div>
                </div>
            </div>
            <div className='h-80 bg-white text-black'>
                <Editoor
                    editorHtml={editorHtml}
                    setEditorHtml={setEditorHtml}
                    placeholder='Write here...'/>
            </div>
            <div className='flex justify-between w-full pt-4'>
                {/*<button*/}
                {/*    onClick={ClearText}*/}
                {/*    className='bg-red-600 text-white p-2 rounded-md w-40'*/}
                {/*>*/}
                {/*    Clean*/}
                {/*</button>*/}
                <button
                    onClick={CreatePost}
                    className='btn btn-secondary rounded-md'
                >
                    Додати статтю
                </button>
            </div>
        </div>
    );
};

export default observer(CreatePost);