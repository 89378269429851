import React, { useContext, useEffect, useState } from 'react';
import "../Posts/ArticleItem.css";
import { Context } from "../../../index";
import { observer } from "mobx-react-lite";

const PopupBannerItem = ({ link, post, title, bannerSeoName, articleSeoName }) => {
    const { storeblog } = useContext(Context);
    const [photoSRC, setPhotoSRC] = useState('')

    useEffect(() => {
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(post, 'text/html');
        const imgTags = parsedHtml.getElementsByTagName('img');
        for (let i = 0; i < imgTags.length; i++) {
            const src = imgTags[0].getAttribute('src');
            setPhotoSRC(src);
        }
    }, []);

    const updateUtmContent = (urlParams, bannerSeoName, articleSeoName) => {

        let params = new URLSearchParams(urlParams);

        // Завжди встановлюємо значення utm_content і utm_term
        params.set('utm_content', articleSeoName);
        params.set('utm_term', bannerSeoName);

        // Перетворюємо назад у строку параметрів
        return `?${params.toString()}`;
    }

    const params = updateUtmContent(storeblog.urlParams, bannerSeoName, articleSeoName)


    return (
        <div className="popup_banner_item">
            <a href={link + params} className="popup_banner h-100 d-flex flex-column">
                <img className="popup_banner-img flex-shrink-0" src={photoSRC} alt='' />
                <div className='px-3 flex-grow-1 d-flex flex-column justify-content-between'>
                    <p className="popup_banner-title py-2">{title}</p>
                    <div class="d-flex justify-content-between">
                        <p>
                            [Оф.сайт]
                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22.703" height="21.928">
                            <path d="M1.056 21.928c0-6.531 5.661-9.034 10.018-9.375V18.1L22.7 9.044 11.073 0v4.836a10.5 10.5 0 0 0-7.344 3.352C-.618 12.946-.008 21 .076 21.928z"></path>
                        </svg>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default observer(PopupBannerItem);